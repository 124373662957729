@import url("https://fonts.googleapis.com/css?family=Mallanna:700|Rubik:400");

body {
  font-family: "Rubik";
  font-weight: 400;
  background: #2e294e;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "undefined";
  font-weight: 700;
}

html {
  font-size: 100%;
} /* 16px */

h1 {
  font-size: 4.21rem; /* 67.36px */
}

h2 {
  font-size: 3.158rem; /* 50.56px */
}

h3 {
  font-size: 2.369rem; /* 37.92px */
}

h4 {
  font-size: 1.777rem; /* 28.48px */
}

h5 {
  font-size: 1.333rem; /* 21.28px */
}

small {
  font-size: 0.75rem; /* 12px */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.vertical-timeline::before {
  background: black !important;
}
.vertical-timeline-element-icon {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

span.vertical-timeline-element-date {
  font-family: "Mallanna", sans-serif;
}

@media only screen and (max-width: 1169px) {
  .vertical-timeline-element {
    position: relative;
    margin: 2em 0;
    width: 70%;
  }
}
